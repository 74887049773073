<template>
  <div id="order" class="grid">
    <div class="pg-header">
      <div class="pg-back-button">
        <button class="btn-primary" @click="back">Tillbaka</button>
      </div>
      <h2 v-if="dayReportId < 1">Ny dagsrapport</h2>
      <h2 v-if="dayReportId > 0">Editera dagsrapport</h2>
    </div>

    <div v-if="myDayReport" class="pg-form">
      <div class="pg-form-group">
        <label class="pg-label">Datum</label>
        <v-date-picker v-model="myDayReport.day_report_date">
          <template #default="{ inputValue, inputEvents }">
            <input class="pg-input-date" :value="inputValue" readonly v-on="inputEvents" />
          </template>
        </v-date-picker>
      </div>
      <div class="pg-form-group">
        <label v-if="isAdmin" class="pg-label">Montör/Säljare</label>
        <label v-if="isSeller" class="pg-label">Säljare</label>
        <label v-if="isWorker" class="pg-label">Montör</label>
        <select v-model="myDayReport.user_id" class="pg-input">
          <option v-for="user in myUsers" :key="user.user_id" :value="user.user_id">
            {{ user.full_name }}
          </option>
        </select>
      </div>
      <div v-if="isWorker || isAdmin" class="pg-form-group">
        <label class="pg-label">Antal delmonterade</label>
        <input v-model="myDayReport.partially_assembled_amount" class="pg-input" type="number" />
      </div>
      <div v-if="isWorker || isAdmin" class="pg-form-group">
        <label class="pg-label">Antal slutmonterade</label>
        <input v-model="myDayReport.final_assembled_amount" class="pg-input" type="number" />
      </div>
      <div v-if="isWorker || isAdmin" class="pg-form-group">
        <label class="pg-label">Antal slutmonterade ellås</label>
        <input v-model="myDayReport.final_assembled_electric_locks_amount" class="pg-input" type="number" />
      </div>
      <div v-if="isSeller || isAdmin" class="pg-form-group">
        <label class="pg-label">Antal sålda boxar</label>
        <input v-model="myDayReport.sold_boxes_amount" class="pg-input" type="number" />
      </div>
      <div class="pg-form-group">
        <label class="pg-label">Kommentar</label>
        <textarea v-model="myDayReport.comments" class="pg-input"></textarea>
      </div>
      <br />
      <div class="pg-form-group">
        <button class="btn-primary" @click="saveMyDayReport">Spara</button>
      </div>
      <div v-if="dayReportId > 0" class="pg-form-group">
        <button class="btn-danger" @click="deleteMyDayReport">Radera</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { format } from 'date-fns';

export default {
  name: 'DayReport',
  props: {
    dayReportId: {
      type: Number,
      default: 0
    },
    originView: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      search: '',
      showError: false,
      error: '',
      currentUser: null,
      myUsers: [],
      myDayReport: {
        day_report_date: new Date(),
        user_id: 0,
        user_full_name: '',
        partially_assembled_amount: 0,
        final_assembled_amount: 0,
        final_assembled_electric_locks_amount: 0,
        sold_boxes_amount: 0,
        comments: ''
      }
    };
  },
  computed: {
    isAdmin: function () {
      return this.$store.getters.isAdmin;
    },
    isWorker: function () {
      return this.$store.getters.isWorker;
    },
    isSeller: function () {
      return this.$store.getters.isSeller;
    }
  },
  watch: {
    'myDayReport.user_id': function (newVal) {
      for (let i = 0; i < this.myUsers.length; i++) {
        let obj = this.myUsers[i];
        for (let key in obj) {
          let attrName = key;
          let attrValue = obj[key];
          if (attrName === 'user_id') {
            if (attrValue === newVal) {
              this.myDayReport.user_full_name = obj['full_name'];
            }
          }
        }
      }
    }
  },
  created() {},
  async mounted() {
    this.currentUser = this.$store.getters.currentUser;

    const ADMIN = 1;
    const SELLER = 2;
    const WORKER = 3;

    if (this.currentUser.role_id == 1) {
      await this.fetchMyUsersByRole(SELLER);
      await this.fetchMyUsersByRole(WORKER);
    }
    if (this.currentUser.role_id == 2) {
      await this.fetchMyUsersByRole(SELLER);
    }
    if (this.currentUser.role_id == 3) {
      await this.fetchMyUsersByRole(WORKER);
    }

    if (this.dayReportId > 0) {
      await this.fetchMyDayReport();
    }
    await this.myUser();
  },
  methods: {
    ...mapActions({
      fetchDayReport: 'reports/fetchDayReport',
      addDayReport: 'reports/addDayReport',
      updateDayReport: 'reports/updateDayReport',
      deleteDayReport: 'reports/deleteDayReport',
      fetchWorkers: 'users-utils/fetchWorkers',
      fetchUsersByRole: 'users-utils/fetchUsersByRole'
    }),

    async myUser() {
      const loggedInUserId = this.currentUser.user_id;

      let userInArray = false;
      let firstLoop = true;
      let firstUserIdInArray = 0;
      for (let i = 0; i < this.myUsers.length; i++) {
        let obj = this.myUsers[i];
        for (let key in obj) {
          let attrName = key;
          let attrValue = obj[key];
          if (attrName === 'user_id') {
            if (firstLoop) {
              firstUserIdInArray = attrValue;
              firstLoop = false;
            }

            if (attrValue === loggedInUserId) {
              userInArray = true;
            }
          }
        }
      }
      if (userInArray) {
        this.myDayReport.user_id = loggedInUserId;
      } else {
        this.myDayReport.user_id = firstUserIdInArray;
      }
    },
    async deleteMyDayReport() {
      const result = confirm(`Är du säker att du vill radera dagsrapport?`);
      if (result) {
        let response = null;
        try {
          let data = {
            day_report_id: this.dayReportId
          };
          response = await this.deleteDayReport(data);
        } catch (error) {
          // console.log(error);
          // TODO : FIX ERROR LOG
        } finally {
          let addUpdateOK = false;
          if (response.success == 1) {
            addUpdateOK = true;
          }
          this.$router.replace({
            name: this.originView,
            params: {
              addUpdateOK: addUpdateOK,
              hasAddUpdate: true,
              action: 'DELETE'
            }
          });
          // console.log("We do cleanup here");
        }
      }
    },
    back: function () {
      this.$router.replace({
        name: this.originView,
        params: {
          addUpdateOK: false,
          hasAddUpdate: false,
          action: ''
        }
      });
    },
    /*
    async fetchMyWorkers() {
      try {
        this.myWorkers = [];

        this.myWorkers = await this.fetchWorkers();
      } catch (error) {
        //   console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    },*/
    async fetchMyUsersByRole(role_id) {
      try {
        let data = {
          role_id: role_id
        };

        let result = await this.fetchUsersByRole(data);
        this.myUsers.push(...result);
      } catch (error) {
        //   console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    },
    async fetchMyDayReport() {
      try {
        this.myDayReport = [];
        let data = {
          day_report_id: this.dayReportId
        };
        this.myDayReport = await this.fetchDayReport(data);
      } catch (error) {
        //  console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    },
    async saveMyDayReport() {
      let response = null;

      try {
        let data = {
          day_report_id: this.dayReportId,
          day_report_date: format(new Date(this.myDayReport.day_report_date), 'yyyy-MM-dd'),
          user_id: this.myDayReport.user_id,
          user_full_name: this.myDayReport.user_full_name,
          partially_assembled_amount: this.myDayReport.partially_assembled_amount,
          final_assembled_amount: this.myDayReport.final_assembled_amount,
          final_assembled_electric_locks_amount: this.myDayReport.final_assembled_electric_locks_amount,
          sold_boxes_amount: this.myDayReport.sold_boxes_amount,
          comments: this.myDayReport.comments
        };

        if (this.dayReportId > 0) {
          response = await this.updateDayReport(data);
        } else {
          response = await this.addDayReport(data);
        }
      } catch (error) {
        //  console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              //  console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        let addUpdateOK = false;

        if (response && response.success == 1) {
          addUpdateOK = true;

          this.$router.replace({
            name: this.originView,
            params: {
              addUpdateOK: addUpdateOK,
              hasAddUpdate: true,
              action: 'SAVE'
            }
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
  height: 130px;
}
</style>
